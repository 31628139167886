import React, { useEffect } from "react";
import cx from "classnames";
import SanityImage from "gatsby-plugin-sanity-image";
import { Link } from "gatsby";
import BlogIndexQuery from "../static-queries/blogIndexQuery";
import PortableText from "../components/portableText";
import { Seo } from "../components/seo";
import sanityPlainText from "../lib/sanityPlainText";
import YoutubeEmbed from "../components/youtubeEmbed";

const BlogPost = ({ pageContext: { blogPost }, location }) => {
  useEffect(() => {
    if (typeof document !== "undefined") {
      document.body.style.overflow = "auto";
    }
  }, []);

  const description = blogPost.excerpt
    ? sanityPlainText(blogPost.excerpt)
    : null;

  const meta = {
    metaTitle: blogPost.title,
    openTitle: blogPost.title,
    twitterTitle: blogPost.title,
    metaDescription: description,
    openGraphDescription: description,
    twitterDescription: description,
    openImage: blogPost.mainImage,
    twitterImage: blogPost.mainImage,
  };

  return (
    <>
      <Seo metaInfo={meta} location={location} />

      <section className={cx("mt-mnav md:mt-dnav")}>
        <div className="container pt-2 md:mb-14 mb-6">
          <div className="md:border-t border-black">
            <div className="py-6">
              <Link
                to={`/${BlogIndexQuery().slug.current}`}
                className="uppercase text-2xs md:text-xs underline"
              >
                {BlogIndexQuery().blogTitle}
              </Link>
            </div>
            <div className="lg:grid grid-cols-12 gap-10">
              <div className="col-span-2"></div>
              <div className="col-span-8">
                <h1 className="text-lg2 sm:text-xl md:text-3xl font-semibold">
                  {blogPost.title}
                </h1>
                {blogPost.excerpt && (
                  <PortableText
                    className="font-serif w-full mt-5 mb-9 text-sm md:text-base"
                    blocks={blogPost.excerpt}
                  />
                )}
                {blogPost.mainVideo?.youtubeUrl ? (
                  <YoutubeEmbed youtubeVideo={blogPost.mainVideo} />
                ) : (
                  blogPost.mainImage && (
                    <div className="flush-component">
                      <SanityImage
                        className="w-full"
                        alt={blogPost.title}
                        {...blogPost.mainImage}
                        width={870}
                        height={435}
                      />
                    </div>
                  )
                )}
              </div>
              <div className="col-span-2"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="container">
        <div className="border-t md:py-16 pb-2 pt-3 border-black">
          <div className="lg:grid grid-cols-12 gap-10">
            <div className="col-span-2">
              {blogPost.authors?.map((author) => (
                <Link
                  key={author.id}
                  to={`/people?slug=${author.slug.current}`}
                  className="mb-5"
                >
                  {author && author.image && (
                    <SanityImage
                      className="w-full hidden lg:block md:mb-3.5"
                      alt={blogPost.title}
                      {...author.image}
                      width={200}
                      height={250}
                    />
                  )}
                  {author && (
                    <div className="uppercase mb-9 text-3xs">
                      BY &nbsp;
                      <span className="md:underline">
                        {author && author.name}
                      </span>
                    </div>
                  )}
                </Link>
              ))}
            </div>

            {blogPost.body && (
              <div className="col-span-8">
                <PortableText className="font-serif" blocks={blogPost.body} />
              </div>
            )}
            <div className="col-span-2"></div>
          </div>
        </div>
      </section>

      {blogPost.categories && blogPost.categories.length > 0 && (
        <section className="container font-serif text-xs md:text-base">
          <div className="border-t pt-6 pb-16 md:pt-11 md:pb-24 border-black">
            <div>
              Tags:
              {blogPost.categories.map((category, index) => (
                <span key={category.id}>
                  &nbsp;
                  <Link
                    className="underline"
                    to={`/${BlogIndexQuery().slug.current}?category=${
                      category._id
                    }`}
                  >
                    {category.title}
                  </Link>
                  {index !== blogPost.categories.length - 1 && ","}
                </span>
              ))}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default BlogPost;
